const LOGIN = "api/common/admin/login/";
const ACCOUNTList = "api/common/account/";
const USERList = "api/common/user/";
const ADMINUserCreate = "api/common/user-create/";
const AdminUserCount = "api/common/admin/count/";

export const API = {
  LOGIN,
  ACCOUNTList,
  USERList,
  ADMINUserCreate,
  AdminUserCount,
};
