<template>
  <div class="d-flex flex-column">
    <StandarPageWithList
      title="All Users"
      :activeNo="activeSystemUsers"
      :empty="!systemUsers.length"
    >
      <template #action><span></span></template>
      <template #empty-content>
        <div class="d-flex flex-column align-center">
          <h3 class="mb-2">No users found</h3>
          <p class="text-center mb-8">
            You need some users in your <br />
            organization
          </p>
          <Button
            :plusIcon="'add-icon.svg'"
            :general="true"
            :label="'Add System Admin'"
            @onClick="addUser('add')"
            class="empty-btn"
          ></Button>
        </div>
      </template>
      <div class="px-5 mb-5">
        <SearchBar
          class="admin-user-searchbar"
          @onSearch="onFilter"
          type="button"
          :label="appliedFilters.length > 0 ? 'More Filters' : 'Filters'"
          :length="appliedFilters.length"
          :chipText="appliedFilters"
          ghostText="Search"
          :add="true"
          btn-text="Add System Admin"
          @onClick="addUser"
          :valueSearch="filterText"
          @removeFilter="removeFilter"
        ></SearchBar>
      </div>
      <DataTable
        :pagination="paginationModel"
        :headers="headers"
        :data="filteredUsers"
        @onRequest="requestData"
      >
        <template #no-data> </template>
        <template #user="{ row }">
          <span class="admin-name" @click="gotoAccounts(row)">
            <UserAdminCard
              class="my-4"
              :fullName="
                row.user.data.first_name + ' ' + row.user.data.last_name
              "
              :email="row.user.data.email"
            ></UserAdminCard>
          </span>
        </template>
        <template #account_access="{ row }">
          <div>
            <ExUserAccessBadge
              v-if="
                row.user.data.account_permissions &&
                row.user.data.account_permissions.length > 0
              "
              :account_name="row.user.data.account_permissions[0].account_name"
              :permission="
                getPermissionType(row.user.data.account_permissions[0])
              "
              :access="getAccessType(row.user.data.account_permissions[0])"
              account_status="Open"
              :accounts_count="row.user.data.account_permissions.length - 1"
            />
          </div>
        </template>
        <template #lastLogin="{ row }">
          <div class="last-login-text">
            {{ row?.user?.data?.last_login || "" | formatDate }}
          </div>
        </template>
        <template #status="{ row }">
          <UserAdminStatusBadge
            :status="
              row.user.data.state[0].toUpperCase() +
              row.user.data.state.substring(1)
            "
          />
        </template>
        <template #actions="{ row }">
          <div>
            <DropDownMenu
              @onClick="triggerDropdownAction(row.user.data, $event)"
              :is-open="false"
              :dropdown-items="getAdminUsersItems(row.user.data, appUser)"
              scrollContainerClass=".ex-main-container"
              attachSelector=".ex-main-container"
              :absolute="true"
            >
              <template #activator>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </template>
            </DropDownMenu>
          </div>
        </template>
      </DataTable>
    </StandarPageWithList>
    <ExActionsPopUp
      v-if="userAccountActionDialogObject?.show"
      :headline="userAccountActionDialogObject?.title"
      :htmlInfo="userAccountActionDialogObject.description"
      :actionLabel="userAccountActionDialogObject?.actionText"
      :value="userAccountActionDialogObject?.show"
      :primary="userAccountActionDialogObject.primary"
      :termination="userAccountActionDialogObject.termination"
      :destructive="userAccountActionDialogObject.destructive ?? false"
      :avatar="{
        picture: '',
        firstName: currentUser?.first_name || '',
        lastName: currentUser?.last_name || '',
      }"
      @onAction="OnActionUserAccountDialog"
      @input="OnCloseUserAccountDialog"
    />
    <DeleteAdminUserPopup
      :show="userActions.showDeleteUserPopup"
      :user="{
        id: currentUser?.id || 0,
        firstName: currentUser?.first_name || '',
        lastName: currentUser?.last_name || '',
      }"
      @onDeleteAdminUser="deleteAdminUser"
      @onClose="onCloseActionPopup"
    />
    <ExCreateUserWizard
      :dialog="addUserDialog"
      :accessLevel="'owner'"
      @created="onCreatedUser"
      :isSuperUser="isSuperUser"
      @dialog-closed="addUserDialog = false"
      :allUsers="systemUsers"
    />
    <RenameAdminUserPopup
      v-model="userActions.showRenameUserPopup"
      @input="onCloseActionPopup"
      @onSave="renameAdminUser"
      :current-user="currentUser"
      :existingNames="filteredUsers.map((el) => el.first_name)"
    />
    <CancelInviteAdminUserPopup
      :show="userActions.showCancelInviteUserPopup"
      :user="{
        id: currentUser?.id || 0,
        firstName: currentUser?.first_name || '',
        lastName: currentUser?.last_name || '',
      }"
      @onCancelInviteAdminUser="cancelInviteAdminUser"
      @onClose="onCloseActionPopup"
    />
    <SearchModal
      :dialog="showFilterDialog"
      @back="showFilterDialog = false"
      @checkedItemsChanged="filteredModalUsers"
      :clearSelection="clearSelection"
      filterType="Users"
      :filterGroups="filterGroups"
      :statusOptions="statusOptions"
      :modalWidth="620"
      :defaultSelected="appliedFilters"
    />
    <ResendInviteAdminUserPopup
      :show="userActions.showResendInviteUserPopup"
      :user="{
        id: currentUser?.id || 0,
        firstName: currentUser?.first_name || '',
        lastName: currentUser?.last_name || '',
      }"
      @onResendInviteAdminUser="resendInviteAdminUser"
      @onClose="onCloseActionPopup"
    />
    <NoRecordFound
      v-if="filteredUsers?.length === 0 && systemUsers?.length != 0"
      :title="title"
      :message="title"
      :btnTitle="'System Admin'"
      :subtitle="''"
      @onAction="addUser('add')"
      class="no-record-found account-not-found"
      :clearLabel="clearBtnLabel"
      :showClearAction="true"
      @onClear="clearSearchFilter()"
      :description="emptyRecordDescription"
    />
  </div>
</template>
<script>
import StandarPageWithList from "@components/common/PageHeaderWithButton.vue";
import DataTable from "@components/DataTable/DataTable.vue";
import SearchBar from "@components/SearchBar/SearchBar.vue";
import UserAdminCard from "@components/common/UserAdminCard.vue";
import DropDownMenu from "@components/DropDownMenu/DropDownMenu.vue";
import UserAdminStatusBadge from "@components/UserAdminStatusBadge/UserAdminStatusBadge.vue";
import ExUserAccessBadge from "@components/UserAccessBadge/ExUserAccessBadge.vue";
import Button from "@components/buttons/Button.vue";
import dropdownMixin from "@components/DropDownMenu/dropdownMixin";
import DeleteAdminUserPopup from "@components/AdminUserActions/DeleteAdminUserPopup.vue";
import RenameAdminUserPopup from "@components/AdminUserActions/RenameAdminUserPopup.vue";
import {
  getSystemUsers,
  deleteAdminUser,
  renameAdminUser,
  cancelInviteUser,
  blockAdminUser,
  unBlockAdminUser,
  closeUser,
  unCloseUser,
  resendInviteAdminUser,
} from "@/api";
import CancelInviteAdminUserPopup from "@components/AdminUserActions/CancelInviteAdminUserPopup.vue";
import ResendInviteAdminUserPopup from "@components/AdminUserActions/ResendInviteAdminUserPopup.vue";
import ExCreateUserWizard from "@components/ExCreateUserWizard.vue";
import ExActionsPopUp from "@components/common/ExActionsPopUp.vue";
import SearchModal from "@components/SearchBar/SearchModal.vue";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
import NoRecordFound from "@components/NoRecordFound/NoRecordFound.vue";
import clearStateMixin from "@components/views/mixins/clearStateMixin.js";

export default {
  name: "AdminDataTable",
  components: {
    SearchBar,
    DataTable,
    StandarPageWithList,
    UserAdminCard,
    DropDownMenu,
    ExUserAccessBadge,
    UserAdminStatusBadge,
    Button,
    DeleteAdminUserPopup,
    ExCreateUserWizard,
    RenameAdminUserPopup,
    CancelInviteAdminUserPopup,
    ExActionsPopUp,
    SearchModal,
    ResendInviteAdminUserPopup,
    NoRecordFound,
  },
  mixins: [dropdownMixin, clearStateMixin],
  props: {
    headers: {
      type: Array,
      default() {
        return [
          {
            text: "User",
            sortable: false,
            value: "user",
            img: "icons/accounts.svg",
          },
          {
            text: "Access to Accounts",
            sortable: false,
            value: "accounts_access",
          },
          {
            text: "Last login",
            sortable: false,
            value: "last_login",
          },
          {
            text: "Status",
            sortable: false,
            value: "status",
          },
          {
            text: "",
            sortable: false,
            value: "actions",
          },
        ];
      },
    },
    pagination: {
      type: Object,
      default() {
        return {
          page: 1,
          total: 0,
          perPage: 10,
        };
      },
    },
  },
  beforeMount() {
    this.appliedFilters = this.getStoredFilters();
  },
  async mounted() {
    this.$loader.show();
    await this.loadUsers();
    this.$loader.hide();
    const user = JSON.parse(localStorage.getItem("user"));
    this.isSuperUser = user.is_superuser;
  },
  data() {
    return {
      filterText: "",
      showFilterDialog: false,
      appliedFilters: [],
      modalDialog: false,
      paginationModel: this.pagination,
      accounts: this.data,
      systemUsers: [],
      currentUser: null,
      userActions: {
        showDeleteUserPopup: false,
        showRenameUserPopup: false,
        showCancelInviteUserPopup: false,
        showBlockUserPopup: false,
        showUnBlockUserPopup: false,
        showCloseUserPopup: false,
        showUnCloseUserPopup: false,
        showResendInviteUserPopup: false,
      },
      addUserDialog: false,
      clearSelection: "",
      statusOptions: ["invited", "operational", "closed", "blocked"],
      filterGroups: [],
      isSuperUser: false,
      title: "Users",
    };
  },
  computed: {
    appUser() {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      return currentUser;
    },
    userAccountActionDialogObject() {
      if (this.userActions.showBlockUserPopup) {
        const firstName = this.currentUser?.first_name || "";
        const lastName = this.currentUser?.last_name || "";
        return {
          title: "Block",
          description: `You are about to block ${firstName} ${lastName}`,
          actionText: "Block",
          show: true,
          primary: false,
          termination: true,
        };
      } else if (this.userActions.showUnBlockUserPopup) {
        const firstName = this.currentUser?.first_name || "";
        const lastName = this.currentUser?.last_name || "";
        return {
          title: "Un-block",
          description: `You are about to un-block ${firstName} ${lastName}`,
          actionText: "Un-block",
          show: true,
          primary: true,
          termination: false,
        };
      } else if (this.userActions.showCloseUserPopup) {
        const firstName = this.currentUser?.first_name || "";
        const lastName = this.currentUser?.last_name || "";
        return {
          title: "Close",
          description: `You are about to close ${firstName} ${lastName}`,
          actionText: "Close",
          show: true,
          primary: false,
          termination: false,
          destructive: true,
        };
      } else if (this.userActions.showUnCloseUserPopup) {
        const firstName = this.currentUser?.first_name || "";
        const lastName = this.currentUser?.last_name || "";
        return {
          title: "Un-close",
          description: `You are about to un-close ${firstName} ${lastName}`,
          actionText: "Un-close",
          show: true,
          primary: true,
          termination: false,
        };
      }
      return {
        title: "",
        description: "",
        actionText: "",
        show: false,
        primary: false,
        termination: false,
      };
    },
    activeSystemUsers() {
      return this.filteredUsers.filter(
        (el) => el.user.data.state == "operational"
      ).length;
    },
    filteredUsers: function () {
      let resultArray = [];
      resultArray = this.systemUsers.map((el) => {
        const newEl = {
          user: {
            slot: "user",
            data: el,
          },
          accountAccess: {
            slot: "account_access",
          },
          lastLogin: {
            slot: "lastLogin",
          },
          status: {
            slot: "status",
          },
          actions: {
            slot: "actions",
          },
        };
        return newEl;
      });
      const statusFilters = ["invited", "operational", "closed", "blocked"];
      if (this.appliedFilters.some((val) => statusFilters.includes(val))) {
        resultArray = resultArray.filter((el2) => {
          return this.appliedFilters.includes(el2.user.data.state);
        });
      }
      if (this.filterText && this.filterText.length > 0) {
        resultArray = resultArray.filter((el) => {
          const fullName = (
            el.user.data.first_name +
            " " +
            el.user.data.last_name
          ).toLowerCase();
          const filterTextLower = this.filterText.toLowerCase();
          return fullName.includes(filterTextLower);
        });
      }
      return resultArray;
    },
  },
  watch: {
    filteredUsers(newVal) {
      this.paginationModel.total = newVal.length;
    },
    appliedFilters(newFilters) {
      this.storeFilters(newFilters);
    },
  },
  methods: {
    getStoredFilters() {
      const filters = localStorage.getItem("userAppliedFilter");
      if (filters) {
        try {
          const parseFilter = JSON.parse(filters);
          return Array.isArray(parseFilter) && parseFilter.length > 0
            ? parseFilter
            : ["invited", "operational"];
        } catch (error) {
          return ["invited", "operational"];
        }
      }
      return ["invited", "operational"];
    },
    storeFilters(filters) {
      localStorage.setItem("userAppliedFilter", JSON.stringify(filters));
    },
    filteredModalUsers(filters) {
      const newArray = filters
        .map((item) => {
          if (item.value !== null) {
            if (typeof item === "string") {
              return item;
            } else if (
              typeof item === "object" &&
              // eslint-disable-next-line no-prototype-builtins
              item.hasOwnProperty("value")
            ) {
              return item.value;
            }
          }
        })
        .filter(Boolean);
      this.appliedFilters = newArray;
      this.showFilterDialog = false;
      this.$emit("appliedFilter", this.appliedFilters);
    },
    removeFilter(removedFilter) {
      this.clearSelection = removedFilter;
      this.$emit("appliedFilter", this.appliedFilters);
    },

    ...mapActions(["setLoadingStatus"]),
    addUser(type) {
      if (type === "add") {
        this.addUserDialog = true;
      } else if (type === "filter") {
        this.showFilterDialog = true;
      }
    },
    async onCreatedUser() {
      await this.loadUsers();
    },
    async loadUsers() {
      const response = await getSystemUsers();
      this.systemUsers = response.data.results;
    },
    onCloseActionPopup() {
      this.currentUser = null;
      this.userActions.showDeleteUserPopup = false;
      this.userActions.showRenameUserPopup = false;
      this.userActions.showCancelInviteUserPopup = false;
      this.userActions.showBlockUserPopup = false;
      this.userActions.showUnBlockUserPopup = false;
      this.userActions.showCloseUserPopup = false;
      this.userActions.showUnCloseUserPopup = false;
      this.userActions.showResendInviteUserPopup = false;
    },
    async deleteAdminUser() {
      this.$loader.setAppLoading(true);
      await deleteAdminUser(this.currentUser.id);
      this.onCloseActionPopup();
      await this.loadUsers();
      this.$loader.setAppLoading(false);
    },
    OnActionUserAccountDialog() {
      if (this.userActions.showBlockUserPopup) {
        this.blockAdminUser();
      } else if (this.userActions.showUnBlockUserPopup) {
        this.unBlockAdminUser();
      } else if (this.userActions.showCloseUserPopup) {
        this.closeAdminUser();
      } else if (this.userActions.showUnCloseUserPopup) {
        this.unCloseAdminUser();
      }
    },
    OnCloseUserAccountDialog() {
      if (this.userActions.showBlockUserPopup) {
        this.userActions.showBlockUserPopup = false;
      } else if (this.userActions.showUnBlockUserPopup) {
        this.userActions.showUnBlockUserPopup = false;
      } else if (this.userActions.showCloseUserPopup) {
        this.userActions.showCloseUserPopup = false;
      } else if (this.userActions.showUnCloseUserPopup) {
        this.userActions.showUnCloseUserPopup = false;
      }
    },
    async blockAdminUser() {
      this.setLoadingStatus(true);
      if (this.currentUser && this.currentUser.id) {
        try {
          await blockAdminUser(this.currentUser.id);
          await this.loadUsers();
        } catch (e) {
          console.log("error: ", e);
        }
      }
      this.setLoadingStatus(false);
      this.OnCloseUserAccountDialog();
    },
    async closeAdminUser() {
      this.setLoadingStatus(true);
      if (this.currentUser && this.currentUser.id) {
        try {
          await closeUser(this.currentUser.id);
          await this.loadUsers();
        } catch (e) {
          console.log("error: ", e);
        }
      }
      this.setLoadingStatus(false);
      this.OnCloseUserAccountDialog();
    },
    async unCloseAdminUser() {
      this.setLoadingStatus(true);
      if (this.currentUser && this.currentUser.id) {
        try {
          await unCloseUser(this.currentUser.id);
          await this.loadUsers();
        } catch (e) {
          console.log("error: ", e);
        }
      }
      this.setLoadingStatus(false);
      this.OnCloseUserAccountDialog();
    },
    async unBlockAdminUser() {
      this.setLoadingStatus(true);
      if (this.currentUser && this.currentUser.id) {
        try {
          await unBlockAdminUser(this.currentUser.id);
          await this.loadUsers();
        } catch (e) {
          console.log("error: ", e);
        }
      }
      this.setLoadingStatus(false);
      this.OnCloseUserAccountDialog();
    },
    getAccessType(account) {
      if (account) {
        if (account.has_access) {
          return "Has Access";
        } else if (account.is_suspended) {
          return "Suspended";
        } else {
          return "Blocked";
        }
      } else {
        return "Blocked";
      }
    },
    getPermissionType(account) {
      if (account) {
        if (account.is_owner) {
          return "Owner";
        } else if (account.is_co_owner) {
          return "Co-Owner";
        } else if (
          account.has_readiness_access ||
          account.has_knowledge_access ||
          account.has_performance_access ||
          account.has_compensation_access
        ) {
          return "App User";
        }
      } else {
        return null;
      }
    },
    onFilter(data) {
      this.filterText = data;
    },
    triggerDropdownAction(data, event) {
      this.currentUser = data;
      switch (event.value) {
        case "delete_action":
          this.userActions.showDeleteUserPopup = true;
          break;
        case "block_action":
          this.userActions.showBlockUserPopup = true;
          break;
        case "unblock_action":
          this.userActions.showUnBlockUserPopup = true;
          break;
        case "close_action":
          this.userActions.showCloseUserPopup = true;
          break;
        case "unclose_action":
          this.userActions.showUnCloseUserPopup = true;
          break;
        case "rename_action":
          this.userActions.showRenameUserPopup = true;
          break;
        case "cancel_invite_action":
          this.userActions.showCancelInviteUserPopup = true;
          break;
        case "resent_invite_action":
          this.userActions.showResendInviteUserPopup = true;
          break;
        default:
          break;
      }
    },
    async requestData(data) {
      this.paginationModel.page = data.page;
    },
    async loadAccounts(pageNo = 1) {
      if (this.data) {
        const startIndex = (pageNo - 1) * this.paginationModel.perPage;
        const endIndex = startIndex + this.paginationModel.perPage;
        this.accounts = this.data.slice(startIndex, endIndex);
        this.paginationModel.total = this.data.length;
      }
    },
    gotoAccounts(data) {
      this.$router.push(`/user/accounts/${data.user.data.id}`);
    },
    async renameAdminUser(newUser) {
      if (this.currentUser && this.currentUser.id) {
        this.$loader.setAppLoading(true);
        try {
          const payload = {
            first_name: newUser.firstName,
            last_name: newUser.lastName,
          };
          await renameAdminUser(this.currentUser.id, payload);
          await this.loadUsers();
          this.userActions.showRenameUserPopup = false;
        } catch (e) {
          console.log("error: ", e);
        }
        this.$loader.setAppLoading(false);
      }
    },
    async cancelInviteAdminUser() {
      this.$loader.setAppLoading(true);
      const userId = this.currentUser?.id;
      try {
        await cancelInviteUser(userId);
        this.onCloseActionPopup();
        await this.loadUsers();
      } catch (e) {
        console.error("error: ", e);
      }
      this.$loader.setAppLoading(false);
    },
    async resendInviteAdminUser() {
      const adminUser = JSON.parse(localStorage.getItem("user"));
      this.$loader.setAppLoading(true);
      try {
        const payload = {
          user: this.currentUser.id,
          account: adminUser.id,
        };
        await resendInviteAdminUser(adminUser.id, payload);
        this.onCloseActionPopup();
        await this.loadUsers();
      } catch (e) {
        console.error("error: ", e);
      }
      this.$loader.setAppLoading(false);
    },
    clearSearchFilter() {
      if (this.filterText?.length > 0 && this.appliedFilters?.length > 0) {
        this.appliedFilters = [];
        this.filterText = "";
        this.$emit("appliedFilter", this.appliedFilters);
      }
      if (this.appliedFilters?.length > 0) {
        this.appliedFilters = [];
        this.$emit("appliedFilter", this.appliedFilters);
      }
      if (this.filterText?.length > 0) {
        this.filterText = "";
      }
    },
  },
  filters: {
    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);

      const day = date.getUTCDate();
      const month = date.toLocaleString("en-US", { month: "long" });
      const year = date.getUTCFullYear();

      const hours = date.getUTCHours().toString().padStart(2, "0");
      const minutes = date.getUTCMinutes().toString().padStart(2, "0");

      return `${day}-${month}-${year} ${hours}:${minutes}`;
    },
  },
};
</script>
<style>
.admin-user-searchbar button,
.admin-empty-content-user-add {
  font-size: 14px !important;
}

.admin-empty-content-user-add {
  width: 170px;
}

.admin-name {
  cursor: pointer;
}
</style>
<style scoped>
.last-login-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #667085;
}
.empty-btn {
  color: white;
}
</style>
