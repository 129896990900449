import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";
import AccountListView from "../views/AccountListView.vue";
import UserAccountsView from "../views/UserAccountsView.vue";
import UsersListView from "../views/UsersListView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import SubscriptionsView from "../views/SubscriptionsView.vue";
import NotFoundView from "@components/PageNotFound/NotFound.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: HomeView,
  // },
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
    meta: { sidebar: false },
  },
  {
    path: "/accounts",
    name: "AccountListView",
    component: AccountListView,
    meta: { sidebar: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordView,
    meta: { sidebar: false },
  },
  {
    path: "/setup/password",
    name: "SetNewPassword",
    component: ForgotPasswordView,
    meta: { sidebar: false },
  },
  {
    path: "/reset/password",
    name: "SetNewPasswordReset",
    component: ForgotPasswordView,
    meta: { sidebar: false },
  },
  {
    path: "/users",
    name: "UsersListView",
    component: UsersListView,
    meta: { sidebar: true },
  },
  {
    path: "/user/accounts/:id",
    name: "UserAccountsView",
    component: UserAccountsView,
    meta: { sidebar: true },
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: SubscriptionsView,
    meta: { sidebar: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/forgot-password",
    "/setup/password",
    "/reset/password",
  ];
  const authRequired = !publicPages.includes(to.path);

  const loggedIn = localStorage.getItem("token");
  store.dispatch("routeErrorHandling/clearError");
  if (authRequired && !loggedIn) {
    if (to.fullPath == "/") {
      return next("/login");
    } else {
      return next({ path: "/login" });
    }
  }
  if (!authRequired) {
    localStorage.clear();
  }
  next();
});

export default router;
