import axios from "axios";
import router from "../router";
import store from "../store";

/**
 * Create Axios Instance for API Call
 *
 * @param {boolean} withToken
 * @param { { headers: Object, baseURL: string } } options
 */
export const createAxiosInstance = (withToken = true, options = {}) => {
  const { headers = {}, baseURL = `${process.env.VUE_APP_BASE_URL}` } = options;

  const instanceConfig = {
    baseURL: baseURL,
    headers: {
      ...(withToken
        ? { Authorization: "Token " + localStorage.getItem("token") }
        : {}),
      ...headers,
    },
  };

  const axiosInstance = axios.create(instanceConfig);

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status, data } = error.response;
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        if (router.currentRoute.path != "/login") {
          router.push({
            path: "/login",
          });
        }
      }
      // Handle 404 Not Found errors (e.g., redirect to a custom 404 page)
      if (
        status === 404 &&
        data &&
        Object.prototype.hasOwnProperty.call(data, "detail") &&
        data.detail === "Not found."
      ) {
        store.dispatch("routeErrorHandling/setError", {
          code: 404,
          message: "Bad request. Please check your input.",
        });
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default createAxiosInstance;
